import { Link } from "react-router-dom";

import { TELEGRAM_CHANNEL_URL, TELEGRAM_SUPPORT_BOT_USERNAME, LOGO_URL, STORE_NAME } from "../../../config";

const Footer = () => {
    return (
        <div className="footer">
            <div className="storefront">
                <div className="footer-content">
                    <div className="footer-branding">
                        <div className="footer-branding-content">
                            <img className="footer-branding-img" src={LOGO_URL} />
                            <div className="footer-nav">
                                <Link to="/">Shop</Link>
                                <Link to="/faq">FAQ</Link>
                                <Link to={`https://t.me/${TELEGRAM_SUPPORT_BOT_USERNAME}`}>Support</Link>
                                <Link to={TELEGRAM_CHANNEL_URL} target="_blank" rel="noopener noreferrer" className={`sno-image`}>
                                    Reviews
                                    <img src={"https://i.imgur.com/y1PYC6t.png"} />
                                </Link>
                            </div>
                        </div>
                        <div className="footer-btm">
                            <div className="footer-branding-txt">Copyright &copy; {new Date().getFullYear()} {STORE_NAME} All rights reserved</div>
                            <div className="footer-social-nav">
                                {/* <div className="footer-social-logo">
                                    <img src={"https://i.imgur.com/1Z7eLok.png"} />
                                </div> */}
                                {/* <div className="footer-social-logo">
                                    <img src={"https://i.imgur.com/JifHoYI.png"} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;