import { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCircleQuestion, faCoins, faArrowRightLong, faRightFromBracket, faUser, faEnvelope, faDollarSign, faNewspaper, faKey, faExternalLink, faExternalLinkSquare, faExternalLinkAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../../contexts/user";
import { API_URL, STORE_NAME, TELEGRAM_SUPPORT_BOT_USERNAME } from "../../../config";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";

Modal.setAppElement("#root");

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999',
        padding: "0"
    },
    overlay: {
        zIndex: "9999"
    }
};

const HeroItem = ({ icon, name, value, to }) => {
    const navigate = useNavigate();

    return (
        <div className="userhome-hero-item" onClick={() => navigate(to)}>
            <div className="userhome-hero-icon"><FontAwesomeIcon icon={icon} /></div>
            <div className="userhome-hero-content">
                <div className="userhome-hero-title">{name}</div>
                <div className="userhome-hero-value">{value}</div>
            </div>
        </div>
    )  
};

const TelegramLink = ({ defaultOpened }) => {
    const [telegramModalOpen, setTelegramModalOpen] = useState(false);
    const [code, setCode] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const copyText = (text) => {
        var input = document.createElement('textarea');
        input.innerHTML = text;
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    };

    useEffect(() => {
        const fetchData = async() => {
            try {
                const request = await fetch(`${API_URL}/user/dashboard/telegram/code`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await request.json();
                if(!data.success) {
                    if(request.status === 403) return navigate("/user/login");
                    setError(data.response);
                    setLoaded(true);
                    return;
                };

                setCode(data.code);
                setLoaded(true);

                if(defaultOpened === true) setTelegramModalOpen(true);
            } catch(e) {
                console.log(e);
                setError(error);
            };
        };
        fetchData();
    }, []);

    if(loaded === false) return <StorefrontLoading fullPage={false} />;

    return (
        <>
            <div className="user-alert">
                <div className="user-alert-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"/></svg>
                </div>
                <div className="user-alert-content">
                    <div className="user-alert-title">Link your telegram account</div>
                    <div className="user-alert-text">Connect your telegram account to your Topalts account for access to our telegram based support system and more</div>
                </div>
                <button className="btn btn-snb" onClick={() => setTelegramModalOpen(true)}>Connect</button>
            </div>

            <Modal
                isOpen={telegramModalOpen}
                onRequestClose={() => setTelegramModalOpen(false)}
                style={modalStyle}
                id={"telegram-connect-modal"}
            >
                {error !== false ? <div className="auth-form-error">{error}</div> : null}
                <div className="storefront-modal-title">Connect your Telegram account</div>
                <div className="storefront-modal-content">
                    <div className="storefront-modal-description">Follow the simple steps below to connect your telegram account</div>
                    
                    <div className="cnt-step">
                        <div className="cnt-step-title">Step 1: Copy the command below</div>
                        <div className="cnt-step-text">This is a unique code that proves to our telegram bot you own this OneStopShop account</div>

                        <div className="cnt-code">
                            <div className="cnt-code-txt">/weblink {code}</div>
                            <button className="btn btn-snb cnt-cpy-btn" onClick={() => copyText(`/weblink ${code}`)}><FontAwesomeIcon icon={faCopy} />Copy</button>
                        </div>
                    </div>

                    <div className="cnt-step">
                        <div className="cnt-step-title">Step 2: Message @{TELEGRAM_SUPPORT_BOT_USERNAME} on Telegram</div>
                        <div className="cnt-step-text">Send the command you copied above to our telegram bot</div>

                        <button className="btn btn-snb cnt-btn" onClick={() => window.open(`https://t.me/${TELEGRAM_SUPPORT_BOT_USERNAME}`, "_blank")}><FontAwesomeIcon icon={faExternalLink} />Open Telegram</button>
                    </div>
                </div>
            </Modal>
        </>
    )
};

const UserValue = ({ icon, title, value }) => {
    return (
        <div className="user-home-vls">
            <div className="uh-vls-icon"><FontAwesomeIcon icon={icon} /></div>
            <div className="uh-vls-title">
                {title}
                <div className="uh-vls-value">{value}</div>
            </div>
        </div>
    )
};

const UserHome = () => {
    const { user, setUser } = useContext(UserContext);
    const [userOrderCount, setUserOrderCount] = useState(0);
    const [userTicketCount, setUserTicketCount] = useState(0);
    const [telegramLinked, setTelegramLinked] = useState(false);
    const [announcementContent, setAnnouncementContent] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const naviagte = useNavigate();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const dataResponse = await fetch(`${API_URL}/user/dashboard/home`, {
                    credentials: "include"
                });
                if(dataResponse.status === 403) return naviagte("/user/login");
                const data = await dataResponse.json();
                if(!data.success) {
                    setLoaded(true);
                    setError(data.response);
                    return;
                };

                setUserOrderCount(data.user_order_count);
                setUserTicketCount(data.user_ticket_count);
                setTelegramLinked(data.telegram_linked);

                const announcementResponse = await fetch(`${API_URL}/announcement`, {
                    credentials: "include"
                });
                const announcementData = await announcementResponse.json();
                if(announcementData.success) {
                    setAnnouncementContent(announcementData.announcement_content);
                };

                setLoaded(true);
            } catch(e) {
                console.log(e);
                setError("An unknown error occurred, please try again later or contact support if the issue persists");
                setLoaded(true);
            };
        };
        fetchData();
    }, []);

    if(error !== false) return <StorefrontError error={error} />;
    if(!loaded) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            <div className="user-home-welcome">
                <div className="user-home-welcome-title"><FontAwesomeIcon icon={faUser} /> Welcome, <span className="text-underline">{user.username}</span>!</div>
                <div className="user-home-welcome-text">Welcome to the {STORE_NAME} user dashboard, you can manage your orders and create support tickets here.</div>
            </div>
            {!telegramLinked ? <TelegramLink defaultOpened={window.location.href.includes("#telegram")} /> : null}
            <div className="user-home-hero">
                <div className="user-home-hero-content">
                    <div className="user-home-hero-vls-container">
                        <UserValue icon={faUser} title="Username" value={user.username} />
                        <UserValue icon={faEnvelope} title="Email" value={user.email} />
                        <UserValue icon={faDollarSign} title="Balance" value={`$${user.balance.toFixed(2)}`} />
                    </div>
                    <div className="user-home-hero-options">
                        <button className="btn btn-nrm btn-nrm-sm"><FontAwesomeIcon icon={faKey} />Change Password</button>
                        <button className="btn btn-nrm btn-nrm-sm" onClick={() => naviagte("/user/logout")}><FontAwesomeIcon icon={faRightFromBracket} />Logout</button>
                    </div>
                </div>
                <div className="user-home-hero-nav">
                    <HeroItem icon={faCartShopping} name="Orders" value={userOrderCount} to="/user/orders" />
                </div>
            </div>
            <div className="user-home-content">
                <div className="user-home-content-title">
                    <FontAwesomeIcon icon={faNewspaper} /> News
                </div>
                <div className="user-home-content-news">
                    {announcementContent
                    ? <div className="news-content">{announcementContent}</div>
                    : <>No news articles have been published yet</>}
                </div>
            </div>
        </div>
    )
};

export default UserHome;