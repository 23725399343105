import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import { QRCodeSVG } from "qrcode.react";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";
import { API_URL } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faEnvelope, faHashtag, faDollarSign, faReceipt, faArrowLeft, faCircleCheck, faCircleExclamation, faClipboard, faClock, faShoppingCart, faTruck } from "@fortawesome/free-solid-svg-icons";

const CryptoLogos = {
    "bitcoin": "https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=026",
    "bitcoincash": "https://cryptologos.cc/logos/bitcoin-cash-bch-logo.svg?v=026",
    "ethereum": "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=026",
    "litecoin": "https://cryptologos.cc/logos/litecoin-ltc-logo.svg?v=026",
    "dogecoin": "https://cryptologos.cc/logos/dogecoin-doge-logo.png?v=026",
    "usdt": "https://cryptologos.cc/logos/tether-usdt-logo.svg?v=026",
    "usdc": "https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=026",
    "pusdc": "https://i.imgur.com/tqARXQr.png",
    "pweth": "https://i.imgur.com/4IcwA81.png",
    "polygon": "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=026"
};

const CryptoTitles = {
    "bitcoin": "BTC",
    "bitcoincash": "BTC Cash",
    "ethereum": "ETH",
    "litecoin": "LTC",
    "dogecoin": "DOGE",
    "usdt": "USDT (ETH)",
    "usdc": "USDC (ETH)",
    "pusdc": "USDC (PLY)",
    "pweth": "WETH (PLY)",
    "polygon": "Matic (PLY)"
};

const CryptoQRPrefix = {
    "bitcoin": "bitcoin:",
    "bitcoincash": "bitcoincash:",
    "ethereum": "ethereum:",
    "litecoin": "litecoin:",
    "dogecoin": "dogecoin:",
    "usdt": "ethereum:0xdAC17F958D2ee523a2206206994597C13D831ec7/transfer?address=",
    "usdc": "",
    "pusdc": "",
    "pweth": "",
    "polygon": "polygon:"
};

const copyText = (text) => {
    var input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
};

const OrderValue = ({ icon, title, value }) => {
    return (
        <div className="user-home-vls">
            <div className="uh-vls-icon"><FontAwesomeIcon icon={icon} /></div>
            <div className="uh-vls-title">
                {title}
                <div className="uh-vls-value">{value}</div>
            </div>
        </div>
    )
};

const OrderContent = ({ orderId, orderTitle, orderTotal, orderStatus, orderPaymentData, orderPaymentMethod, orderProductStockContent, orderCryptoPaymentPending, orderCreatedAt, orderConfirmedAt }) => {
    const [cryptoSelected, setCryptoSelected] = useState(null);
    const [mobileCollapsed, setMobileCollapsed] = useState(false);
    const [showCryptoSelectSreen, setShowCryptoSelectScreen] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if(orderStatus === "awaiting_payment" && orderPaymentMethod === "cryptocurrency" && Object.keys(orderPaymentData).length > 0) {
            setCryptoSelected(Object.keys(orderPaymentData)[0])
        };
    }, []);

    if(orderStatus === "awaiting_payment") {
        if(orderPaymentMethod === "cryptocurrency" && orderCryptoPaymentPending === true ) {
            return (
                <div>
                    <div className="userorder-crypto-view mt-18">
                        <div className="userorder-crypto-view-image-container ucvi-ic">
                            <StorefrontLoading />
                        </div>
                        <div className="userorder-crypto-view-content mt-2">
                            <div className="userorder-crypto-view-title">
                                Payment Pending
                            </div>

                            <div className="userorder-crypto-view-subtitle">
                                Your payment is currently pending on the blockchain.<br />This usually only takes a few minutes, but for some cryptocurrencies it can take up to 60 minutes  
                            </div>

                            <div className="userorder-crypto-view-smtxt">
                                This page updates automatically
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if(orderPaymentMethod === "cryptocurrency" && showCryptoSelectSreen === true) {
            return (
                <div className="checkout-crypto-options">
                    {Object.keys(orderPaymentData).map((crypto_method, index) => {
                        return (
                            <div key={`userorder-crypto-option-${index}-${Math.random.toString()}`} className="checkout-crypto-option" onClick={() => {
                                setMobileCollapsed(!mobileCollapsed)
                                setCryptoSelected(crypto_method)
                                setShowCryptoSelectScreen(false)
                            }}>
                                <img className="userorder-crypto-option-img" src={CryptoLogos[crypto_method]} alt={`${CryptoTitles[crypto_method]} icon`} />
                                <div className="userorder-crypto-option-title">{CryptoTitles[crypto_method]}</div>
                            </div>
                        )
                    })}
                </div>
            )
        } else if(orderPaymentMethod === "cryptocurrency" && showCryptoSelectSreen === false) {
            return (
                <div>
                    <div className="checkout-crypto-back mt-2" onClick={() => setShowCryptoSelectScreen(true)}>
                        <FontAwesomeIcon icon={faArrowLeft} />Back
                    </div>
                    <div className="userorder-crypto-view mt-2">
                        <div className="userorder-crypto-view-image-container">
                            <div className="userorder-crypto-view-image"><QRCodeSVG size={152} value={`${CryptoQRPrefix[cryptoSelected]}${orderPaymentData[cryptoSelected].address}`} /></div>
                        </div>
                        <div className="userorder-crypto-view-content">
                            <div className="userorder-crypto-view-title">
                                <img className="userorder-crypto-view-title-img" src={CryptoLogos[cryptoSelected]} alt={`${CryptoTitles[cryptoSelected]} icon`} />
                                {CryptoTitles[cryptoSelected]}
                            </div>

                            {orderCryptoPaymentPending ? <div className="userorder-crypto-view-pending-notif">
                                <FontAwesomeIcon icon={faClock} /> Your payment is pending on the blockchain
                            </div> : null}

                            <div className="userorder-crypto-view-value">
                                <div className="userorder-crypto-view-value-title">Cryptocurrency Address</div>
                                <div className="userorder-crypto-view-value-text">{orderPaymentData[cryptoSelected].address}</div>
                            </div>

                            <div className="userorder-crypto-view-value">
                                <div className="userorder-crypto-view-value-title">Cryptocurrency Amount</div>
                                <div className="userorder-crypto-view-value-text">{orderPaymentData[cryptoSelected].amount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if(orderPaymentMethod === "cashapp") {
            return (
                <div className="userorder-payment-main mt-18">
                    <div className="userorder-crypto-view">
                        <div className="userorder-crypto-view-image-container">
                            <div className="userorder-crypto-view-image">
                                <img src={`https://api.cash-payments.io/cash-code/${orderPaymentData.cashtag}/${orderTotal}/${orderPaymentData.ref}`} />
                            </div>
                        </div>
                        <div className="userorder-crypto-view-content">
                            <div className="userorder-crypto-view-title">
                                <img className="userorder-crypto-view-title-img" src={"https://i.imgur.com/BBXzIam.png"} alt={`Cashapp icon`} />
                                Cashapp
                            </div>
                            <div className="userorder-crypto-view-subtitle">Please send the exact amount shown below, with the payment note below or your payment may not be automatically credited</div>

                            <div className="userorder-crypto-view-value">
                                <div className="userorder-crypto-view-value-title">Cashtag</div>
                                <div className="userorder-crypto-view-value-text">{orderPaymentData.cashtag}</div>
                            </div>

                            <div className="userorder-crypto-view-value">
                                <div className="userorder-crypto-view-value-title">Payment Note</div>
                                <div className="userorder-crypto-view-value-text">{orderPaymentData.ref}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    } else if(orderStatus === "processing") {
        return (
            <div className="userorder-payment-status-update">
                <div className="userorder-payment-status-update-title"><FontAwesomeIcon icon={faCircleCheck} /> Order Confirmed</div>
                <div className="userorder-payment-status-update-text">Your payment has been confirmed and your order is now in the queue for product delivery.<br />Product delivery only usually takes a few seconds, but it can take up to a few minutes during busy periods.</div>
                <div style={{ marginTop: "190px" }}>
                    <StorefrontLoading />
                </div>
            </div>
        )
    } else if(orderStatus === "completed") {
        return (
            <div>
                <div className="order-alert">
                    <FontAwesomeIcon icon={faCircleCheck} />
                    <div className="order-alert-content">
                        <div className="order-alert-title">Order Completed</div>
                        <div className="order-alert-text">Your order was completed succesfully</div>
                    </div>
                </div>
                <div className="order-alert order-alert-scnd">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <div className="order-alert-content">
                        <div className="order-alert-title">Have an issue?</div>
                        <div className="order-alert-text">Open a support ticket for assistance with your order, we'll get it sorted ASAP</div>
                        <button className="btn btn-nrm btn-nrm-sm mt-2" onClick={() => navigate(`/user/home#telegram`)}><FontAwesomeIcon icon={faEnvelope} />Open support ticket</button>
                    </div>
                </div>
                <div className="order-info">
                    <OrderValue icon={faHashtag} title="ID" value={orderId} />
                    <OrderValue icon={faReceipt} title="Title" value={orderTitle} />
                    <OrderValue icon={faDollarSign} title="Total" value={`$${orderTotal.toFixed(2)}`} />
                    <OrderValue icon={faClock} title="Created At" value={new Date(orderCreatedAt).toLocaleString()} />
                </div>
            </div>
        )
    } else if(orderStatus === "cancelled") {
        return (
                <div className="userorder-crypto-view mt-18">
                    <div className="userorder-crypto-view-image-container ucvi-ic">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                    </div>
                    <div className="userorder-crypto-view-content mt-0">
                        <div className="userorder-crypto-view-title">
                            Order Cancelled
                        </div>

                        <div className="userorder-crypto-view-subtitle w75">
                            Your order has expired<br />If you sent a payment, please <Link className="lnk-txt" to="/user/tickets/new">create a support ticket</Link>. Orders expire after 60 minutes if no payment has been detected. If you still want to purchase the product, please create another order.
                        </div>

                        <button className="btn btn-nrm btn-sm mt-18" onClick={() => navigate("/")}><FontAwesomeIcon icon={faShoppingCart} /> Go back to store</button>

                        <div className="userorder-crypto-view-smtxt">
                            This page updates automatically
                        </div>
                    </div>
                </div>
            
        )
    };
};

const OrderProductStock = ({ stock }) => {
    const [copyButtonTick, setCopyButtonTick] = useState(false);

    return (
        <div className="storefront-product-stock-container">
            <div className="storefront-cart-item-title dflx">
                <div className="storefront-cart-content-title">Stock</div>
                <button className="btn btn-nrm btn-nrm-sm btn-nrm-xsm" disabled={copyButtonTick} onClick={() => {
                    copyText(stock.join("\n"));
                    setCopyButtonTick(true);
                    setTimeout(() => setCopyButtonTick(false), 1000);
                }}>
                    {copyButtonTick 
                        ? <><FontAwesomeIcon icon={faCircleCheck} />Copied</>
                        : <><FontAwesomeIcon icon={faClipboard} />Copy</>
                    }
                </button>
            </div>
            <div className="userorder-content">
                {stock.map(s => s.split("\n").map(b => <div key={`order-content-line-${Math.random.toString()}`}>{b}</div>))}
            </div>
        </div>
    )
};

const OrderProduct = ({ image, name, quantity, price_per_item, stock }) => {
    return (
        <div className="storefront-cart-item">
            <div className="storefront-cart-item-title">
                <div className="storefront-cart-content-title">{name}</div>
            </div>
            <div className="storefront-cart-item-content">
                <img className="storefront-cart-image" src={image} alt="Item icon" />
                <div className="storefront-cart-item-options">
                    <div className="storefront-cart-content-quantity">${price_per_item.toFixed(2)} x {quantity}</div>
                    <div className="storefront-cart-content-price">${(price_per_item * quantity).toFixed(2)}</div>
                </div>
            </div>
            {stock.length > 0 ? <OrderProductStock stock={stock} /> : null}
        </div>
    )
};

const OrderProducts = ({ products }) => {
    return (
        <div>
            {products.map((product, index) => {
                return <OrderProduct key={`order-item-${index}`} image={product.image} name={product.name} quantity={product.quantity} price_per_item={product.price_per_item} stock={product.stock} />;
            })}
        </div>
    )
};

const TrackOrder = () => {
    const [orderId, setOrderId] = useState(null); 
    const [orderTitle, setOrderTitle] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderProducts, setOrderProducts] = useState([]);
    const [orderPaymentData, setOrderPaymentData] = useState(null);
    const [orderPaymentMethod, setOrderPaymentMethod] = useState(null);
    const [orderCryptoPaymentPending, setOrderCryptoPaymentPending] = useState(true);
    const [orderProductStockContent, setOrderProductStockContent] = useState(null);
    const [orderConfirmedAt, setOrderConfirmedAt] = useState(null);
    const [orderCreatedAt, setOrderCreatedAt] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    let interval = undefined;

    const getOrderData = () => {
        try {
            if(!window.location.href.includes("/orders/")) {
                if(interval !== undefined) clearInterval(interval);
                return;
            };
            let orderData = window.location.href.split(`/orders/`)[1];
            
            if(!orderData.includes("#")) {
                orderData = orderData.replaceAll("/", "");
                return { order_id: orderData, token: "null" };
            } else {
                let orderToken = orderData.split("#")[1].replaceAll("/", "");
                orderData = orderData.split("#")[0].replaceAll("/", "");
                return { order_id: orderData, token: orderToken };
            };
        } catch(e) {
            console.log(e);
        };
    };

    const fetchData = async() => {
        let orderData = getOrderData();
        try {
            const data = await fetch(`${API_URL}/orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    order_id: orderData.order_id,
                    token: orderData.token
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            setOrderId(data.order.order_id);
            setOrderTitle(data.order.title);
            setOrderProducts(data.order.products);
            setOrderStatus(data.order.status);
            setOrderTotal(data.order.total);
            setOrderPaymentData(data.order.payment_data);
            setOrderPaymentMethod(data.order.payment_method);
            setOrderProductStockContent(data.order.order_stock_content);
            setOrderCryptoPaymentPending(data.order.crypto_payment_pending);
            setOrderCreatedAt(data.order.created_at);
            setOrderConfirmedAt(data.order.confirmed_at);
            setLoaded(true);
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
        };
    };

    useEffect(() => {
        fetchData();
        
        if(orderStatus !== "completed") {
            interval = setInterval(() => {
                fetchData();
            }, 15 * 1000);
        };
    }, []);

    useEffect(() => {
        if(orderStatus === "completed") {
            if(interval !== undefined) clearInterval(interval);
        };
    }, [orderStatus]);

    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userorder-tracking">
            <div className="userorder-data">
                <div className="userorder-data-other">
                    <div className="storefront-cart-title">
                        <div className="sft-title">Order Overview</div>
                        <div className="sft-products">{1} {1 !== 1 ? "products" : "product"}</div>
                    </div>
                    <OrderProducts products={orderProducts} />
                </div>
                <div className="userorder-data-main">
                    <div className="userorder-select-payment-main">
                        <div className="storefront-cart-title">
                            <div className="sft-title">{orderStatus === "awaiting_payment" ? `${orderPaymentMethod === "cryptocurrency" ? "Cryptocurrency" : "Cashapp"} Checkout` : "Order Information"}</div>
                            <div className="sft-products capitalize">{orderStatus === "awaiting_payment" && !orderCryptoPaymentPending ? <Countdown date={new Date(new Date(orderCreatedAt).getTime() + 3600000)} daysInHours={true} /> : orderCryptoPaymentPending && orderStatus === "awaiting_payment" ? <span>Pending</span> : <span>{orderStatus}</span>}</div>
                        </div>
                        <OrderContent orderId={orderId} orderTitle={orderTitle} orderTotal={orderTotal} orderStatus={orderStatus} orderPaymentData={orderPaymentData} orderPaymentMethod={orderPaymentMethod} orderProductStockContent={orderProductStockContent} orderCryptoPaymentPending={orderCryptoPaymentPending} orderCreatedAt={orderCreatedAt} orderConfirmedAt={orderConfirmedAt} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TrackOrder;