import { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Turnstile } from "@marsidev/react-turnstile";
import { Link } from "react-router-dom";
import { API_URL, CLOUDFLARE_TURNSTILE_SITEKEY } from "../../../config";
import UserContext from "../../../contexts/user";

const Register = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(false);
    const { setUser } = useContext(UserContext);
    const captchaRef = useRef();
    const navigate = useNavigate();

    const submit = async() => {
        try {
            const data = await fetch(`${API_URL}/auth/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username,
                    email: email.toLowerCase(),
                    password,
                    captcha
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setCaptcha(null);
                setError(data.response);
                captchaRef.current?.reset();
                return;
            };

            setUser({
                loggedin: true,
                username: data.user.username,
                email: data.user.email,
                balance: data.user.balance
            });
            Cookies.set("loggedin", "true");
            Cookies.set("username", data.user.username);
            Cookies.set("email", data.user.email);
            Cookies.set("balance", data.user.balance);
            navigate("/user/home");
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
        };
    };

    return (
        <div className="auth-form-page">
            <div className="auth-form">
                <div className="auth-form-title">Sign Up</div>
                
                {error !== false ? <div className="auth-form-error">{error}</div> : null}

                <div className="auth-form-inputs">
                    <input className="auth-form-input" placeholder="Username" type="text" onChange={(e) => setUsername(e.target.value)} />
                    <input className="auth-form-input" placeholder="Email" type="text" onChange={(e) => setEmail(e.target.value)} />
                    <input className="auth-form-input" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="auth-form-captcha">
                    <Turnstile siteKey={CLOUDFLARE_TURNSTILE_SITEKEY} ref={captchaRef} onSuccess={(token) => setCaptcha(token)} onExpire={() => setCaptcha(null)} onError={() => setCaptcha(null)} />
                </div>

                <button className="btn auth-form-btn" disabled={captcha === null} onClick={() => submit()}>Sign Up</button>
                <div className="auth-form-sub"><span>Already have an account?</span> <Link to="/user/login">Login</Link></div>
            </div>
        </div>
    )
};

export default Register;