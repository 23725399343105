import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import StorefrontLoading from "../partials/loading";
import StorefrontError from "../partials/error";
import { API_URL } from "../../../config";  

const OrdersTable = ({ orders }) => {
    const naviagte = useNavigate();

    if(!(orders.length > 0)) return (
        <StorefrontError error="You haven't made any orders yet!" alert={true} />
    );
    
    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order, index) => (
                    <tr key={`order-table-value-${index}-${Math.random.toString()}`}>
                        <td>{order.title}</td>
                        <td>${order.total.toFixed(2)}</td>
                        <td><div className={`userorders-table-status userorders-table-status-${order.status.toLowerCase()}`}>{order.status.replaceAll("_", " ")}</div></td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => naviagte(`/orders/${order.order_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async() => {
            try {
                const data = await fetch(`${API_URL}/user/dashboard/orders`, {
                    method: "GET",
                    credentials: "include"
                }).then(r => r.json());
                if(!data.success) {
                    setError(data.response);
                    setLoaded(true);
                    return;
                };
                setOrders(data.orders);
                setLoaded(true);
            } catch(e) {
                setError("An unknown error occurred, please try again later!");
            }
        };
        fetchData();
    }, []);
    
    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return <StorefrontLoading fullPage={true} />;

    return (
        <div className="userhome">
            <div className="user-home-welcome">
                <div className="user-home-welcome-title">Orders</div>
                <div className="user-home-welcome-text">View and track your orders</div>
            </div>
            <OrdersTable orders={orders} />
        </div>
    );
};

export default Orders;