import { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import CartContext from "../../../contexts/cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../../../config";

Modal.setAppElement("#root");

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: "0"
    },
};

const Item = ({ name, description, image, tags, products }) => {
    const [productModalOpen, setProductModalOpenState] = useState(false);
    const [product, setProduct] = useState({});
    const [quantity, setProductQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { cart, setCart } = useContext(CartContext);
    const [error, setError] = useState(false);

    const setQuantity = (newQuantity) => {
        if (newQuantity >= 5000) return;
        setProductQuantity(newQuantity);
    };

    const closeModal = () => {
        setProductModalOpen(false);
    };

    const setProductModalOpen = (value) => {
        if (value === true) {
            document.body.setAttribute("style", "overflow: hidden;");
        } else {
            document.body.setAttribute("style", "");
        };
        setProductModalOpenState(value);
    };

    const getCheapestPrice = (products) => {
        if (!(products.length > 0)) return 0;
        return products.reduce((prev, curr) => prev.price < curr.price ? prev : curr).price;
    };

    const addToCart = async () => {
        if (quantity < product.min_quantity) setProductQuantity(product.min_quantity);

        setLoading(true);
        setError(false);
        try {
            const data = await fetch(`${API_URL}/cart/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    item_id: product.product_id,
                    quantity: parseInt(quantity)
                }),
                credentials: "include"
            }).then(r => r.json());
            setLoading(false);
            if (!data.success) return setError(data.response);

            if (cart.findIndex(p => p.product_id === product.product_id) === -1) {
                setCart([...cart, {
                    product_id: product.product_id,
                    quantity,
                    price_per_item: product.price
                }]);
            } else {
                setCart(cart.map(p => {
                    if (p.product_id === product.product_id) {
                        p.quantity += quantity;
                        return p;
                    } else return p;
                }));
            };

            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
                setProduct(products[0]);
                setQuantity(products[0].min_quantity);
                setProductModalOpen(false);
            }, 1500);
        } catch (e) {
            setError("An unknown error occurred, please try again later or contact support if the issues persists");
        }
    };

    useEffect(() => {
        products = products.sort((a, b) => a.price - b.price);
        setProduct(products[0]);
        setQuantity(products[0].min_quantity);
    }, []);

    useEffect(() => {
        setProductQuantity(product.min_quantity);
    }, [product]);

    return (
        <>
            <div className="storefront-item">
                <img className="storefront-item-image" src={image} />
                <div className="storefront-item-content">
                    <div className="storefront-item-name">{name}</div>
                    {/* <div className="storefront-item-price">From ${getCheapestPrice(products).toFixed(2)}</div> */}
                    <button className="btn storefront-item-button" onClick={() => setProductModalOpen(true)}>
                        Purchase | ${products[0].price.toFixed(2)}
                    </button>
                </div>
            </div>
            <Modal
                isOpen={productModalOpen}
                onRequestClose={closeModal}
                style={modalStyle}
                id={`product-modal-${`product-${name}-modal`}`}
            >
                <div className="storefront-modal-title">{name}</div>
                <div className="storefront-modal-content">
                    {error !== false ? <div className="auth-form-error mt-2">{error}</div> : null}
                    <div className="storefront-modal-description">{description.split("\n").map((s, index) => <div key={`storefront-modal-desc-line-${name}-${name}-${index}`} className="text-line">{s}</div>)}</div>

                    <div className="storefront-product-tags">
                        {tags.length > 0 ? <div className="storefront-product-tags-title">Tags</div> : null}
                        <div className="storefront-product-tags-container">
                            {tags.map((tag, index) => (
                                <div key={`storefront-item-tag-${index}-${name}`} className="storefront-product-tag" style={{ background: tag.color_hex }}>{tag.name}</div>
                            ))}
                        </div>
                    </div>

                    <div className="input-group">
                        <label>Product</label>

                        <div className="auth-form-input hover-pointer" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            <div className="products-dropdown-value">
                                <span>{product.name}</span>
                                <span>{product.stock} • ${product.price ? product.price.toFixed(2) : 0}</span>
                            </div>
                        </div>
                        <div className="products-dropdown-options" style={{ display: dropdownOpen ? "" : "none" }}>
                            {products.map((product_obj, index) => {
                                return <div className="products-dropdown-value products-dropdown-option-value" key={`storefront-item-value-${index}-${name}`} onClick={() => {
                                    setProduct(product_obj);
                                    setDropdownOpen(false);
                                }}>
                                    <span>{product_obj.name}</span>
                                    <span>{product_obj.stock} • ${product_obj.price.toFixed(2)}</span>
                                </div>;
                            })}
                        </div>
                    </div>

                    <div className="input-group">
                        <div className="storefront-quantity-text">Amount to add</div>
                        <input className="auth-form-input" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} min={product.min_quantity} max={5000} />
                    </div>

                    <button className="btn auth-form-btn storefront-modal-btn disabled-alwayswhite" disabled={showSuccess || loading || product.out_of_stock === true} onClick={() => addToCart()}>
                        {showSuccess
                            ? <><FontAwesomeIcon icon={faCircleCheck} /> Success</>
                            : <><FontAwesomeIcon icon={faCartShopping} /> Add To Cart (${(product.price * quantity).toFixed(2)})</>
                        }
                    </button>
                </div>
            </Modal>
        </>
    )
};

export default Item;