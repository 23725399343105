import { useContext, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import { API_URL, CLOUDFLARE_TURNSTILE_SITEKEY } from "../../../config";

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const captchaRef = useRef();
    const navigate = useNavigate();

    const submit = async() => {
        try {
            const data = await fetch(`${API_URL}/auth/resetpassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email,
                    captcha
                }),
                credentials: "include"
            }).then(r => r.json());
            if(!data.success) {
                setCaptcha(null);
                setError(data.response);
                captchaRef.current?.reset();
                return;
            };

            setSuccess(data.response);
        } catch(e) {
            setError("An unknown error occurred, please try again later!");
        };
    };

    return (
        <div className="auth-form-page">
            <div className="auth-form">
                <div className="auth-form-title">Reset Password</div>

                {success !== false ? <div className="form-info">{success}</div> : null}
                {error !== false ? <div className="auth-form-error">{error}</div> : null}

                <div className="auth-form-inputs">
                    <input className="auth-form-input" placeholder="Email" type="text" onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="auth-form-captcha">
                    <Turnstile siteKey={CLOUDFLARE_TURNSTILE_SITEKEY} ref={captchaRef} onSuccess={(token) => setCaptcha(token)} onExpire={() => setCaptcha(null)} onError={() => setCaptcha(null)} />
                </div>

                <button className="btn auth-form-btn" disabled={captcha === null} onClick={() => submit()}>Reset Password</button>
                <div className="auth-form-sub"><span>Remember your password?</span> <Link to="/user/login">Login</Link></div>
            </div>
        </div>
    );
};

export default ResetPasswordRequest;